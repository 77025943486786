<script lang="tsx">
import type { PropType, ShallowRef, SlotsType } from 'vue'
import { Transition } from 'vue'
import {
    useFloating,
    offset,
    flip,
    arrow,
    shift,
    autoUpdate,
    size,
    type Middleware,
    type Placement
} from '@floating-ui/vue'

type DefaultPlacement = 'top' | 'right' | 'bottom' | 'left'
type Padding = number | Partial<{
    top: number
    right: number
    bottom: number
    left: number
}>

export type BaseUiPopupProps = {
    modelValue?: boolean
    reference: HTMLElement | null
    /**
     * The side on which to place the floating element.
     */
    placement?: DefaultPlacement | `${DefaultPlacement}-start` | `${DefaultPlacement}-end`
    /**
     * The number of pixels how much the floating element should be
     * offset from the reference element.
     * @default 8
     */
    offset?: number | {
        mainAxis?: number
        crossAxis?: number
        alignmentAxis?: number | null
    }
    /**
     * The number of pixels of padding to the edge of the screen.
     * @default 16
     */
    safePadding?: Padding
    /**
     * Options for the arrow.
     */
    arrow?: {
        /**
         * The number of pixels indicating the distance from the edge of the floating element
         * which the arrow should not exceed when adjusting its position.
         *
         * This can be useful when the floating element has rounded corners, for example, and
         * the arrow should not be placed on top of the rounded corners.
         */
        padding?: number
    }
    /**
     * Whether to allow the floating element to flip to the opposite side if there is not enough space.
     * @default false
     */
    flip?: boolean
    /**
     * Whether to lock the scroll when the floating element is open.
     * @default false
     */
    lockScroll?: boolean
    /**
     * Elements to ignore when checking if the click was outside the floating element.
     * When clicking on these elements, the floating element will not close.
     */
    ignoreElements?: HTMLElement[]
    /**
     * Whether to disable managing the popup with the global
     * popups manager for this specific popup.
     */
    disablePopupManager?: boolean
    /**
     * Whether the floating element should set its minimum width to match the reference element's width.
     * @default false
     */
    matchMinReferenceWidth?: boolean
    floatingWrapperClass?: string
    /**
     * The render method to use for the floating element.
     * This prop affects how the floating element is rendered when it is not open.
     * - `mount`: uses `v-if` to display the floating element
     * - `display`: uses `v-show` to display the floating element
     * @default 'mount'
     */
    renderMethod?: 'mount' | 'display'
    /**
     * Whether the root child node should be used for the root node of the floating element.
     *
     * If enabled, you should control the visibility of the root node with `isShown`
     * and bind the `rootAttrs` to it too. Both of these values are provided in the `default` slot data.
     *
     * It is important to set the `renderMethod` to `'display'` if you want to use `v-show`
     * to control the visibility of the custom root node.
     *
     * @example Usage with `as-child` prop with `v-show`:
     * <BaseUiPopup as-child render-method="display" v-slot="{ rootAttrs, isShown }">
     *     <div v-show="isShown" v-bind="rootAttrs">
     *
     * @example Usage with `as-child` prop with `v-if` (render method defaults to `'mount'`):
     * <BaseUiPopup as-child render-method="mount" v-slot="{ rootAttrs }">
     *     <div v-bind="rootAttrs">
     * @default false
     */
    asChild?: boolean
}

export type BaseUiPopupSlots = {
    default: {
        rootAttrs: {
            ref: Readonly<ShallowRef<HTMLDivElement | null>>
            style: Record<string, string>
            class: string[]
            [key: string]: any
        }
        isShown: boolean
    }
    arrow: {}
}

type ComponentOptions = {}

const DEFAULT_OFFSET = 8    // 0.5rem
const SAFE_PADDING = 16 // 1rem

export function defineComponentBaseUiPopup<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiPopupProps, BaseUiPopupSlots>) {
    return defineComponent(
        (props: BaseUiPopupProps, ctx) => {
            if (import.meta.dev) {
                // check if props.placement includes 'start' or 'end'
                const isAlignedPlacement = props.placement?.includes('-start') || props.placement?.includes('-end')
                if (!isAlignedPlacement && typeof props.offset === 'object' && props.offset?.alignmentAxis !== undefined) {
                    warnLog('[BaseUiPopup] The \'offset.alignmentAxis\' prop is only relevant when the \'placement\' prop includes \'-start\' or \'-end\'.')
                }
            }


            const floating = useTemplateRef<HTMLDivElement>('floating')
            const floatingContent = ref<HTMLDivElement | null>(null)
            const arrowEl = useTemplateRef<HTMLDivElement>('arrow')

            const isOpen = computed<boolean | undefined>({
                get() {
                    return props.modelValue
                },
                set(val) {
                    if (val === undefined) return
                    ctx.emit('update:modelValue', val)
                },
            })

            const shouldRenderComponent = ref<boolean>(isOpen.value ?? false)
            const arrowDimensions = ref<{ width: number, height: number }>({
                width: 0,
                height: 0,
            })
            watch(isOpen, (val) => {
                if (val) {
                    shouldRenderComponent.value = true

                    nextTick(() => {
                        arrowDimensions.value = {
                            width: arrowEl.value?.clientWidth ?? 0,
                            height: arrowEl.value?.clientHeight ?? 0,
                        }
                    })
                }
            })
            onMounted(() => {
                if (isOpen.value) {
                    shouldRenderComponent.value = true
                    arrowDimensions.value = {
                        width: arrowEl.value?.clientWidth ?? 0,
                        height: arrowEl.value?.clientHeight ?? 0,
                    }
                }
            })

            function afterLeave() {
                shouldRenderComponent.value = false
            }

            const reference = computed(() => props.reference ?? null)
            const isArrowPresent = ctx.slots.arrow !== undefined || options?.slots?.arrow
            const initialPlacement = computed(() => props.placement)

            function getArrowPlacementStaticSide(placement: Placement) {
                const directionMap = {
                    'top': 'bottom',
                    'top-start': 'bottom',
                    'top-end': 'bottom',

                    'right': 'left',
                    'right-start': 'left',
                    'right-end': 'left',

                    'bottom': 'top',
                    'bottom-start': 'top',
                    'bottom-end': 'top',

                    'left': 'right',
                    'left-start': 'right',
                    'left-end': 'right',
                }
                return placement in directionMap
                    ? directionMap[placement]
                    : undefined
            }

            const middleware = computed(() => {
                const arr: Middleware[] = []

                if (props.offset) {
                    const offsetVal = typeof props.offset === 'number' ? props.offset + arrowDimensions.value.height : {
                        mainAxis: props.offset.mainAxis !== undefined
                            ? props.offset.mainAxis === 0 ? 0 : props.offset.mainAxis + arrowDimensions.value.height
                            : DEFAULT_OFFSET + arrowDimensions.value.height,
                        alignmentAxis: props.offset.alignmentAxis,
                        // TODO: remove `?? 0` when https://github.com/floating-ui/floating-ui/issues/3044 is fixed
                        crossAxis: props.offset.crossAxis ?? 0,
                    }

                    arr.push(offset(offsetVal))
                }

                arr.push(shift({
                    padding: props.safePadding ?? SAFE_PADDING,
                }))

                // should always come after shift
                if (props.flip) {
                    arr.push(flip())
                }

                arr.push(size((state) => ({
                    padding: props.safePadding ?? SAFE_PADDING,
                    apply: ({
                        availableWidth,
                        availableHeight,
                        elements,
                    }) => {
                        Object.assign(elements.floating.style, {
                            // not shrinking the width in order to apply the shift
                            maxWidth: `${availableWidth}px`,
                            maxHeight: `${availableHeight}px`,
                            ...(props.matchMinReferenceWidth
                                ? {
                                    minWidth: `${state.rects.reference.width}px`,
                                }
                                : {}
                            ),
                        })
                    },
                })))

                if (isArrowPresent) {
                    arr.push(arrow({
                        element: arrowEl,
                        padding: props.arrow?.padding,
                    }))
                }

                return arr
            })

            const { floatingStyles, middlewareData, placement } = useFloating(reference as Parameters<typeof useFloating>[0], floating, {
                placement: initialPlacement,
                middleware: middleware,
                whileElementsMounted: autoUpdate,
            })

            const arrowPlacementStaticSide = computed<string | undefined>(() => getArrowPlacementStaticSide(placement.value))

            const parentScope = getScopeIdAttr()

            function closePopup() {
                isOpen.value = false
            }

            if (props.disablePopupManager !== true) {
                useManagePopupOpening(isOpen, {
                    closeCallback: closePopup,
                    lockScroll: props.lockScroll,
                    closeOnClickOutside: floatingContent,
                    ignoreElements: () => [...(props.ignoreElements ?? []), reference.value],
                })
            }

            return () => {
                function renderFloatingContent(includeDefaultSlot: boolean = true) {
                    return [
                        includeDefaultSlot && renderSlot(ctx.slots.default, options?.slots?.default, {
                            rootAttrs: {
                                ref: floatingContent,
                                style: {
                                    maxHeight: 'inherit',
                                    maxWidth: 'inherit',
                                },
                                class: ['flex flex-col items-center', ctx.attrs.class as string],
                                ...parentScope,
                            },
                            isShown: !!isOpen.value,
                        }),
                        isArrowPresent && (
                            <div
                                ref="arrow"
                                style={{
                                    transform: arrowPlacementStaticSide.value === 'bottom' ? 'scaleY(-1)'
                                        : arrowPlacementStaticSide.value === 'left' ? 'rotate(-90deg) translate(-50%, 50%)'
                                            : arrowPlacementStaticSide.value === 'right' ? 'rotate(90deg) translate(-50%, 50%) scaleX(-1)'
                                                : undefined,
                                    transformOrigin: arrowPlacementStaticSide.value === 'left' ? 'left'
                                        : arrowPlacementStaticSide.value === 'right' ? 'right'
                                            : undefined,
                                    position: 'absolute',
                                    left:
                                    middlewareData.value.arrow?.x != null
                                        ? `${middlewareData.value.arrow.x}px`
                                        : '',
                                    top:
                                    middlewareData.value.arrow?.y != null
                                        ? `${middlewareData.value.arrow.y}px`
                                        : '',
                                    ...(arrowPlacementStaticSide.value
                                        ? {
                                            [arrowPlacementStaticSide.value]: `-${arrowDimensions.value.height}px`,
                                        }
                                        : {}),
                                }}
                            >
                                {renderSlot(ctx.slots.arrow, options?.slots?.arrow, {})}
                            </div>
                        ),
                    ]
                }

                return shouldRenderComponent.value || props.renderMethod === 'display' ? (
                    <div
                        ref="floating"
                        class={['z-10 box-border flex flex-col', {
                            'flex-col-reverse': arrowPlacementStaticSide.value === 'bottom',
                        }, props.floatingWrapperClass]}
                        style={floatingStyles.value}
                    >
                        <Transition appear onAfterLeave={afterLeave}>
                            {isOpen.value || props.renderMethod === 'display' ?
                                props.asChild
                                    ? renderSlot(ctx.slots.default, options?.slots?.default, {
                                        rootAttrs: {
                                            ref: floatingContent,
                                            style: {
                                                maxHeight: 'inherit',
                                                maxWidth: 'inherit',
                                            },
                                            class: ['flex flex-col items-center', ctx.attrs.class as string],
                                            ...parentScope,
                                        },
                                        isShown: !!isOpen.value,
                                    })
                                    : <div
                                        ref={floatingContent}
                                        v-show={isOpen.value}
                                        style={{
                                            maxHeight: 'inherit',
                                            maxWidth: 'inherit',
                                        }}
                                        class={['flex flex-col items-center', ctx.attrs.class]}
                                        {...parentScope}
                                    >
                                    {...renderFloatingContent()}
                                    </div>
                                : null}
                        </Transition>
                    </div>
                ) : null
            }
        },
        {
            inheritAttrs: false,
            props: {
                modelValue: {
                    type: Boolean as PropType<BaseUiPopupProps['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? true,
                },
                reference: {
                    type: [Object, null] as PropType<BaseUiPopupProps['reference']>,
                    default: options?.props?.reference?.default,
                    required: options?.props?.reference?.required ?? false,
                },
                placement: {
                    type: String as PropType<BaseUiPopupProps['placement']>,
                    default: options?.props?.placement?.default ?? 'bottom',
                    required: options?.props?.placement?.required ?? false,
                },
                offset: {
                    type: [Number, Object] as PropType<BaseUiPopupProps['offset']>,
                    default: options?.props?.offset?.default ?? DEFAULT_OFFSET,
                    required: options?.props?.offset?.required ?? false,
                },
                safePadding: {
                    type: [Number, Object] as PropType<BaseUiPopupProps['safePadding']>,
                    default: options?.props?.safePadding?.default ?? SAFE_PADDING,
                    required: options?.props?.safePadding?.required ?? false,
                },
                arrow: {
                    type: Object as PropType<BaseUiPopupProps['arrow']>,
                    default: options?.props?.arrow?.default,
                    required: options?.props?.arrow?.required ?? false,
                },
                flip: {
                    type: Boolean as PropType<BaseUiPopupProps['flip']>,
                    default: options?.props?.flip?.default ?? false,
                    required: options?.props?.flip?.required ?? false,
                },
                lockScroll: {
                    type: Boolean as PropType<BaseUiPopupProps['lockScroll']>,
                    default: options?.props?.lockScroll?.default ?? false,
                    required: options?.props?.lockScroll?.required ?? false,
                },
                ignoreElements: {
                    type: Array as PropType<BaseUiPopupProps['ignoreElements']>,
                    default: options?.props?.ignoreElements?.default ?? [],
                    required: options?.props?.ignoreElements?.required ?? false,
                },
                disablePopupManager: {
                    type: Boolean as PropType<BaseUiPopupProps['disablePopupManager']>,
                    default: options?.props?.disablePopupManager?.default ?? false,
                    required: options?.props?.disablePopupManager?.required ?? false,
                },
                matchMinReferenceWidth: {
                    type: Boolean as PropType<BaseUiPopupProps['matchMinReferenceWidth']>,
                    default: options?.props?.matchMinReferenceWidth?.default ?? false,
                    required: options?.props?.matchMinReferenceWidth?.required ?? false,
                },
                floatingWrapperClass: {
                    type: String as PropType<BaseUiPopupProps['floatingWrapperClass']>,
                    default: options?.props?.floatingWrapperClass?.default,
                    required: options?.props?.floatingWrapperClass?.required ?? false,
                },
                renderMethod: {
                    type: String as PropType<BaseUiPopupProps['renderMethod']>,
                    default: options?.props?.renderMethod?.default ?? 'mount',
                    required: options?.props?.renderMethod?.required ?? false,
                },
                asChild: {
                    type: Boolean as PropType<BaseUiPopupProps['asChild']>,
                    default: options?.props?.asChild?.default ?? false,
                    required: options?.props?.asChild?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiPopupSlots>,
            emits: {
                'update:modelValue': (val: boolean) => true,
            },
        }
    )
}

export default defineComponentBaseUiPopup()

</script>

<style lang="scss" scoped>

</style>
